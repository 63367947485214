import React from 'react'

const WhatshappRecord = () => {
  return (
    <>
      <div dir="auto" className="css-901oao r-14lw9ot r-1dzdj1l" style={{ lineHeight: "138px", marginRight: "28px", marginLeft: "28px", textAlign: "center" }}>No Order</div>

    </>
  )
}

export default WhatshappRecord