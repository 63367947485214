// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD9WuZj3gTeel_HimmZNU_awqPDoln17Nc",
  authDomain: "fieewin.firebaseapp.com",
  projectId: "fieewin",
  storageBucket: "fieewin.appspot.com",
  messagingSenderId: "174832792287",
  appId: "1:174832792287:web:9e54ab36b436af25d1af6a",
  measurementId: "G-NRN8F49F2Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app